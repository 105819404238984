import {
	Button,
	CircularProgress,
	StylesProvider,
	Typography,
} from "@material-ui/core";
import { Fragment, Suspense, lazy, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./App.css";
import "./Transitions.css";
import "./components/ActionButton.css";

import { MiniPersonIcon } from "./components/ExtraIcons";
import { init, setCurrentQuiz, setPage } from "./redux/actions/coreActions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import SignInDialog from "./components/SignInDialog";
import HomeNavbar from "./components/pages/HomeNavbar";
const AppContent = lazy(() => import("./AppContent"));

function App(props) {
	const page = useSelector((state) => state.core.page);
	const token = useSelector((state) => state.core.token);

	const [showSignIn, setShowSignIn] = useState(false);
	//const isDesktop = useMediaQuery("(min-width: 1024px)");
	const [loaded, setLoaded] = useState(false);
	const params = useParams();

	const [pp, setPP] = useState(false);
	const ref = useRef();

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		setLoaded(true);
	}, [token]);

	const handleShowSignIn = () => {
		setShowSignIn(true);
	};

	const handleHideSignIn = () => {
		setShowSignIn(false);
	};

	const homeOrSignOut = () => {
		if (page === "home") {
			setShowSignIn(true);
		} else {
			setPage("home");
		}
	};
	return (
		<div className={`App ${!token && loaded ? "appHolding " : ""}`} ref={ref}>
			<StylesProvider injectFirst>
				<header
					id="appHeader"
					className={`${!token ? "appHeaderHolding" : ""}`}
				>
					<div id="appHeaderLogos">
						<div id="logoLeft">
							<img src="/images/TFSH_Logo_Inside.svg" />
						</div>

						<div id="logoSpacer">
							{" "}
							{token && !params.quizid ? (
								<div className={`navbarLines`}>
									<HomeNavbar onHomeSignOut={homeOrSignOut} page={page} />
								</div>
							) : null}
						</div>
						<div id="logoRight">
							<img src="/images/MINI_Logo_Fill.svg" />
						</div>
					</div>

					{loaded && !token ? (
						<div className={`holdingSignIn`}>
							<Button
								id="btnHeader"
								onClick={handleShowSignIn}
								variant="outlined"
								startIcon={<MiniPersonIcon />}
							>
								<Typography>SIGN IN</Typography>
							</Button>
						</div>
					) : null}
				</header>

				{token ? (
					<Suspense
						fallback={
							<div className="loadingSpinner">
								<CircularProgress />
							</div>
						}
					>
						<AppContent page={props.page} showSignIn={handleShowSignIn} />
					</Suspense>
				) : null}
				{showSignIn && <SignInDialog close={handleHideSignIn} token={token} />}

				{!token || (token && page === "home") ? (
					<footer>
						<div className="privacyPolicyLink" onClick={() => setPP(true)}>
							Privacy Policy
						</div>
						{token ? (
							<div className="contactLink">
								<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
									{process.env.REACT_APP_SUPPORT_EMAIL}
								</a>
								<br />
								07385 788 181
							</div>
						) : null}
					</footer>
				) : null}
				{pp ? <PrivacyPolicy close={() => setPP(false)} /> : null}
			</StylesProvider>
		</div>
	);
}

export default App;
